import { render, staticRenderFns } from "./AddVendorForm.vue?vue&type=template&id=780896e3&scoped=true&"
import script from "./AddVendorForm.vue?vue&type=script&lang=ts&"
export * from "./AddVendorForm.vue?vue&type=script&lang=ts&"
import style0 from "./AddVendorForm.vue?vue&type=style&index=0&id=780896e3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780896e3",
  null
  
)

export default component.exports