


































import { Component, Vue } from 'vue-property-decorator'
import { TableFilterDataType } from '@/types/general'
import InputCommon from '@/components/common/InputCommon.vue'

@Component({
  components: { InputCommon },
})
export default class VendorsFilters extends Vue {
  filtersForm = {
    ID: '',
    UF_NAME: '',
    UF_PREFIX: '',
    SHOP_CITY: '',
    UF_POINTS: '',
  }

  handleAddFilters(): void {
    const data: TableFilterDataType[] = []
    Object.entries(this.filtersForm).forEach(([key, value]) => {
      if (value) {
        data.push({
          target: key,
          data: value,
        })
        this.filtersForm[key] = ''
      }
    })

    this.$emit('add-filters', data)
  }
}
