


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { VendorsListItemType } from '@/types/vendors'

@Component
export default class VendorsCard extends Vue {
  @Prop({ type: Object })
  vendor!: VendorsListItemType

  handleShowVendorDetail(): void {
    this.$emit('show-vendor', this.vendor.ID)
  }
}
