

































































































































import { Component, Vue, Model } from 'vue-property-decorator'
import { Upload } from 'element-ui'
import InputCommon from '@/components/common/InputCommon.vue'
import { ElForm } from 'element-ui/types/form'

export type VendorFormType = {
  name: string
  login: string
  lastName: string
  firstName: string
  prefix: string
  email: string
  photo: null | any
}

@Component({
  components: {
    InputCommon,
  },
})
export default class AddVendorForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  addVendorForm: VendorFormType = {
    name: '',
    login: '',
    lastName: '',
    firstName: '',
    prefix: '',
    email: '',
    photo: null,
  }

  $refs!: {
    upload: Upload
    form: ElForm
  }

  get createError(): string {
    return this.$store.getters['vendors/createResponse'].error
  }

  uploadPhoto(file): void {
    this.addVendorForm.photo = file
  }

  clearPhoto(): void {
    this.$refs.upload.clearFiles()
    this.addVendorForm.photo = null
  }

  async handleAddVendor(): Promise<void> {
    const data = {
      UF_NAME: this.addVendorForm.name,
      UF_PREFIX: this.addVendorForm.prefix,
      PERSONAL_PHOTO: null,
      LOGIN: this.addVendorForm.login,
      EMAIL: this.addVendorForm.email,
      NAME: this.addVendorForm.firstName,
      LAST_NAME: this.addVendorForm.lastName,
    }

    if (this.addVendorForm.photo) {
      data.PERSONAL_PHOTO = this.addVendorForm.photo.raw
    }

    await this.$store.dispatch('vendors/createVendors', data)

    if (!this.createError) {
      this.$emit('add-vendor')

      this.$refs.form.resetFields()
    }
  }

  handleCloseDialog(): void {
    this.$refs.form.resetFields()

    this.$emit('change', false)
  }
}
