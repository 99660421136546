














































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Drawer from '@/components/common/Drawer.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import Info from '@/components/common/Info.vue'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import { VendorsListItemType } from '@/types/vendors'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import SelectCommon from '@/components/common/SelectCommon.vue'
import AddVendorForm from '@/components/vendors/AddVendorForm.vue'
import VendorsTable from '@/components/vendors/VendorsTable.vue'
import VendorsCard from '@/components/mobile/vendors/VendorsCard.vue'
import VendorsFilters from '@/components/mobile/vendors/VendorsFilters.vue'
import VendorsDetail from '@/components/vendors/VendorsDetail.vue'
import { Message } from 'element-ui'
import TopUpBalance from '@/components/vendors/TopUpBalance.vue'

@Component({
  components: {
    TopUpBalance,
    VendorsDetail,
    VendorsFilters,
    VendorsCard,
    VendorsTable,
    AddVendorForm,
    SelectCommon,
    FilterTags,
    EllipseCommon,
    DialogInfo,
    Info,
    Drawer,
  },
})
export default class Vendors extends Vue {
  loading = false
  loadingInfo = false
  isAnimationEnd = false

  page = 1

  filter = 'Все'
  filters: TableFilterType[] = []

  order: TableOrderType | Record<string, any> = {}

  showVendorInfo = false

  addVendorDialog = false
  addVendorInfo = false
  topUpDialog = false

  filterNames = {
    ID: 'Id',
    UF_NAME: 'Логин',
    UF_PREFIX: 'Префикс',
    ADMIN: 'Администратор',
    '<=UF_BALANCE': 'Баланс до',
    '>=UF_BALANCE': 'Баланс от',
  }

  filterOptions = [
    {
      label: 'Все',
      value: 'Все',
    },
    {
      label: 'Топ 10',
      value: '10',
    },
    {
      label: 'Топ 100',
      value: '100',
    },
  ]

  query = {
    isTop: 0,
    topLimit: 10,
  }
  params = {
    filter: {},
    order: {},
    offset: 0,
  }

  showFilters = false
  showVendorsFilters = false

  //TODO приходят не все данные для таблицы
  get vendors(): VendorsListItemType[] {
    return this.$store.getters['vendors/vendorsList']
  }

  get vendorsDetail(): VendorsListItemType {
    return this.$store.getters['vendors/vendorsDetail']
  }

  get vendorsTotal(): string {
    return this.$store.getters['vendors/vendorsTotal']
  }

  get vendorsExport(): TableExportType {
    return this.$store.getters['vendors/exportVendors']
  }

  get showedVendorsCount() {
    if (this.vendorsTotal) {
      return 10 * this.page > parseInt(this.vendorsTotal)
        ? this.vendorsTotal
        : 10 * this.page
    } else {
      return 0
    }
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data) => {
      params[data.target] = data.data
    })

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  async handleChangePage(top?: string): Promise<void> {
    const tabs = document.querySelector('.vendors__filter') as HTMLElement

    tabs.scrollIntoView({ behavior: 'smooth' })

    this.params.filter = this.tableFilters
    this.params.order = this.tableOrder
    this.params.offset = (this.page - 1) * 10

    if (top) {
      this.query.isTop = 1
      this.query.topLimit = +top
    }

    if (top && isNaN(+top)) {
      this.query.isTop = 0
      this.query.topLimit = 10
    }

    this.loading = true

    await this.$store.dispatch('vendors/getVendorsList', {
      params: this.params,
      query: this.query,
    })

    this.loading = false
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data

    this.handleChangePage()
  }

  async handleShowVendorInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showVendorInfo = true

    await this.$store.dispatch('vendors/getVendorsDetail', id)

    this.loadingInfo = false
  }

  handleOpenDialog(): void {
    this.addVendorDialog = !this.addVendorDialog
  }

  async handleOpenTopUpDialog(id: number): Promise<void> {
    this.topUpDialog = true

    await this.$store.dispatch('vendors/getVendorsDetail', id)
  }

  async handleAddVendor(): Promise<void> {
    this.addVendorDialog = false
    this.addVendorInfo = true

    await this.$store.dispatch('notifications/getNotificationsCount')
  }

  handleCloseTopUpBalance(): void {
    this.topUpDialog = false

    this.showVendorInfo = false

    this.handleChangePage()
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  handleAddTableFilter(data: TableFilterType): void {
    const foundFilterIndex = this.filters.findIndex(
      (filter) => filter.target === data.target
    )

    if (foundFilterIndex === -1) {
      if (data.data === '') {
        return
      }

      this.filters.push({
        data: data.data,
        target: data.target,
        name: this.filterNames[data.target],
      })
    } else {
      if (data.data === '') {
        this.handleDeleteFilter(foundFilterIndex)
      }

      this.filters.forEach((item) => {
        if (item.target === data.target) {
          this.handleDeleteFilter(foundFilterIndex)

          this.filters.push({
            data: data.data,
            target: data.target,
            name: this.filterNames[data.target],
          })
        }
      })
    }
  }

  lazyDownload(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.vendorsExport.link
    )
    link.setAttribute('download', this.vendorsExport.name)
    link.click()
    link.remove()
  }

  async handleExportVendors() {
    await this.$store.dispatch('vendors/getExportVendors', {
      filter: this.tableFilters,
      order: this.tableOrder,
      query: this.query,
    })

    if (
      this.vendorsExport.type === 'error' ||
      this.vendorsExport.type === 'queue'
    ) {
      Message.error(this.vendorsExport.text || 'Ошибка')
    } else {
      this.lazyDownload()
    }
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  handleShowVendorsFilters(): void {
    this.showVendorsFilters = !this.showVendorsFilters
  }

  handleSelectFilter(option: string): void {
    this.filter = option

    this.handleShowFilters()
  }

  handleSelectFilters(data: TableFilterType[]): void {
    this.handleShowVendorsFilters()

    data.forEach((value) => {
      this.handleAddTableFilter(value)
    })
  }

  @Watch('filters')
  async onFiltersChange(): Promise<void> {
    this.loading = true

    this.params.filter = this.tableFilters
    this.params.order = this.tableOrder
    this.params.offset = (this.page - 1) * 10

    await this.$store.dispatch('vendors/getVendorsList', {
      params: this.params,
      query: this.query,
    })

    this.loading = false
  }

  @Watch('filter')
  async onFilterChange(currentFilter: string): Promise<void> {
    this.params = {
      filter: {},
      order: {},
      offset: 0,
    }

    this.query = {
      isTop: 0,
      topLimit: 10,
    }

    this.loading = true

    const foundFilterByLabel = this.filterOptions.find(
      (filter) => filter.label === currentFilter
    )

    const foundFilterByValue = this.filterOptions.find(
      (filter) => filter.value === currentFilter
    )

    if (foundFilterByLabel && foundFilterByValue) {
      await this.handleChangePage(foundFilterByLabel.value)

      return
    }

    if (foundFilterByLabel) {
      await this.handleChangePage(foundFilterByLabel.value)
    }

    if (foundFilterByValue) {
      await this.handleChangePage(foundFilterByValue.value)
    }

    this.loading = false
  }

  @Watch('page')
  onPageChange(): void {
    this.params = {
      filter: {},
      order: {},
      offset: 0,
    }

    this.query = {
      isTop: 0,
      topLimit: 10,
    }

    this.handleChangePage()
  }

  async mounted(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('vendors/getVendorsList')

    this.loading = false
  }
}
