







































import { VendorsListItemType } from '@/types/vendors'
import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'

@Component({
  components: { InputCommon },
})
export default class TopUpBalance extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: Object })
  vendor!: VendorsListItemType

  amount = '0'

  handleCloseDialog(): void {
    this.$emit('change', false)

    this.amount = '0'
  }

  async handleSendTopUpBalance(): Promise<void> {
    await this.$store.dispatch('vendors/vendorUpdateBalance', {
      id: this.vendor.ID,
      text: this.amount,
    })

    this.amount = '0'

    this.$emit('top-up-vendor')

    await this.$store.dispatch('notifications/getNotificationsCount')
  }

  setAmountEmpty(type: string): void {
    if (type === 'focus') {
      if (this.amount === '0') {
        this.amount = ''
      }
    } else {
      if (this.amount === '') {
        this.amount = '0'
      }
    }
  }
}
