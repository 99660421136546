




























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { MembersListItemType } from '@/types/members'
import { VendorBalanceType } from '@/types/vendors'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({
  components: {
    InputCommon,
    TableColumnHeader,
  },
})
export default class VendorsTable extends Vue {
  @Prop({ type: Array })
  vendors!: MembersListItemType[]

  @Prop({ type: Boolean })
  loading?: boolean

  idFilter = ''
  nameFilter = ''
  prefixFilter = ''
  balanceFilter = {
    '<=UF_BALANCE': '',
    '>=UF_BALANCE': '',
  }

  handleBalanceFilterSet(filter: VendorBalanceType): void {
    Object.entries(filter).forEach(([key, value]) => {
      this.handleAddFilter({ data: value, target: key })
    })
  }

  handleShowMemberInfo(row: { ID: number }): void {
    this.$emit('show-vendor-info', row.ID)
  }

  handleTopUpBalance(row: { ID: number }): void {
    this.$emit('show-top-up-balance', row.ID)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }
}
